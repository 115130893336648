/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= stylesheet_pack_tag 'stylesheets' %> to the appropriate
// layout file, like app/views/layouts/application.html.slim

@import 'bootstrap/scss/bootstrap';
@import 'select2/src/scss/core';

// Import the Ace editor styles
@import url('ace-builds/css/ace.css');
@import url('ace-builds/css/theme/chrome.css');


// We define our own button style here, since `btn-outline-dark` and `btn-outline-light` do not switch colors.
html[data-bs-theme="dark"] {
  .btn-outline-contrast {
    @extend .btn-outline-light;
  }

  .bg-contrast {
    @extend .bg-light;
  }
}


html[data-bs-theme="light"] {
  .btn-outline-contrast {
    @extend .btn-outline-dark;
  }

  .bg-contrast {
    @extend .bg-dark;
  }
}


$fa-font-path: '@fortawesome/fontawesome-free/webfonts/';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/v4-shims';
